import React from 'react'
import Layout from '../components/layout'
import { Row, Box } from '../components/lgm-grid.js'
import Helmet from 'react-helmet'
import config from '../utils/config'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

class index extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <meta name="Description" content={config.name + ` - startsida`} />
          <title>{config.name} - startsida</title>
          <meta property="og:title" content={config.name + ` - startsida`} />
          <meta
            property="og:description"
            content={config.name + ` - ` + config.description}
          />
          <meta
            property="og:url"
            content={config.url + this.props.location.pathname}
          />
          <meta
            property="og:image"
            content={config.url + `/social_share.jpg`}
          />
        </Helmet>
        <Row backgroundcolor="#e6dbc9">
          <Box>
            <h1>SVERA Utbildningsportal</h1>
            <p>
              Hej och välkommen till SVERA:s utbildningsportal. Här hittar
              du alla våra digitala utbildningar samt material
              till alla våra andra utbildningar. Vi kommer här också att erbjuda
              små enkla guider för att underlätta för er och klubbarnas verksamhet.
            </p>
            <p>
              För tillfället håller vi på att bygga upp våra digitala
              utbildningar. Vi hoppas naturligtvis att ni kommer att lära er mycket här
              och är det något ni saknar är det bara att höra av sig. Nästa kurs som
              planeras är en kurs i jury arbete som skall ge er
              förutsättningar att på bästa sätt kunna utföra arbetet i en jury.
            </p>
            <p>
              Nedan hittar du de utbildningar och guider vi kan erbjuda
              förtillfället, klicka in och lär dig nytt eller fräscha upp de
              kunskaper du redan har!
            </p>
          </Box>
        </Row>
        <Row backgroundcolor="#c53211">
          <Box gridcolumn="2/4">
            <BigLink to="/abr3">
              <Img fluid={this.props.data.imgf.childImageSharp.fluid} />
            </BigLink>
          </Box>
          <Box color="#e6dbc9" gridcolumn="4/8">
            <BigLink to="/abr3">
              <h2>Grundkurs för funktionär A3, B3 och R3</h2>
              <p>
                Skall du hjälpa till som funktionär för första gången? Efter att
                ha gått den här kursen och haft ett samtal med ansvarig
                funktionär på tävlingsplatsen har du en grundläggande kunskap
                att fungera som funktionär vid båt- eller aquabike-tävlingar.
              </p>
              <p>
                Kursen består av en video och ett efterföljande quiz. Efter
                genomförd utbildning kommer du via mejl få en så kallad ABR3
                licens. Kursen tar cirka 20 minuter att genomföra.
              </p>
              <p>
                <b>Klicka här för att komma vidare till kursen!</b>
              </p>
            </BigLink>
          </Box>
        </Row>

        <Row backgroundcolor="#f7b733">
          <Box gridcolumn="2/4">
            <BigLink to="/kompisrescue">
              <Img fluid={this.props.data.imgrescue.childImageSharp.fluid} />
            </BigLink>
          </Box>
          <Box gridcolumn="4/8">
            <BigLink to="/kompisrescue">
              <h2>Kompisräddning</h2>
              <p>
                När vi är ute på vattnet och tränar eller testar något finns
                risk för att en olycka sker. För att minimera riskerna är det
                viktigt att vara väl förberedd. Eftersom man inte har ett rescue
                team på plats får andra som tränar eller en följebåt agera
                rescue team för oss.
              </p>
              <p>
                Kursen består av en video och en länk till information om
                hjärnskakningar. Kursen tar cirka 20 minuter att genomföra.
              </p>
              <p>
                <b>Klicka här för att komma vidare till kursen!</b>
              </p>
            </BigLink>
          </Box>
        </Row>

        <Row backgroundcolor="#0a7e24">
          <Box gridcolumn="2/4">
            <BigLink to="/sjovagsregler">
              <Img fluid={this.props.data.imgsjovagsregler.childImageSharp.fluid} />
            </BigLink>
          </Box>
          <Box color="#e6dbc9" gridcolumn="4/8">
            <BigLink to="/sjovagsregler">
              <h2>Sjövägsregler för oss med motor</h2>
              <p>
              Om du kör båt, vattenskoter eller svävare på vatten så finns ett antal 
              trafikregler du måste följa. Dessa regler är inte jättemånga och ganska 
              enkla att lära sig. Notera att dessa regler även gäller under tävling. 
              Idag regleras sjötrafiken i världen av ”konventionen om internationella 
              regler till förhindrande av kollisioner till sjöss”, ofta förkortad COLREG 
              efter den engelska beskrivningen. 
              </p>
              <p>
                Kursen tar cirka 20 minuter att genomföra.
              </p>
              <p>
                <b>Klicka här för att komma vidare till kursen!</b>
              </p>
            </BigLink>
          </Box>
        </Row>

        <Row backgroundcolor="#e6dbc9">
          <Box>
            <p>
              Är du sugen på att skriva en utbildning för SVERA? Vi behöver
              hjälp med att ta fram fler kurser, är du den som kan hjälpa till
              att skapa nya utbildningar är du mer än välkommen. Kontakta
              kansliet för mer information.
            </p>
          </Box>
        </Row>
      </Layout>
    )
  }
}
export default index

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1056) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    imgf: file(relativePath: { eq: "images/f.jpg" }) {
      ...fluidImage
    }
    imgrescue: file(relativePath: { eq: "images/rescue.jpg" }) {
      ...fluidImage
    }
    imgsjovagsregler: file(relativePath: { eq: "images/sjovagsregler.jpg" }) {
      ...fluidImage
    }
  }
`

const BigLink = styled(Link)`
  color: inherit;
  text-decoration: inherit;
  border: inherit;
`
